
import {
  RevHorizontalSeries,
  RevHorizontalSeriesItem,
  icons,
} from '@backmarket/design-system'

export default {
  components: {
    RevHorizontalSeriesItem,
    RevHorizontalSeries,
  },
  props: {
    blocks: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    iconComponent: (iconName) => {
      return icons[iconName]
    },
  },
}
